import React from 'react';

import './salesforce-form.scss';

const ORG_ID = '00DA0000000IlZt';
const FORM_ACTION = `https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=${ORG_ID}`;
const HIDDEN_INPUTS = {
  orgid: ORG_ID,
  recordType: '012G0000001IonD',
  retURL: 'https://edmunds.com/industry/',
};

export function SalesforceForm() {
  const productList = ['Advertising', 'Web Traffic', 'Website Conversion Tools', 'Vehicle Acquisition', 'Wholesale'];

  return (
    <div className="edmunds-landing-page-form form-container bg-blue-100 mx-auto p-1 p-sm-1_25">
      <h1 className="mb-1_25 text-center text-cool-gray-30 size-30 fw-bold">Let&apos;s drive your sales</h1>
      <p className="mx-auto size-16 text-center">
        Edmunds has a solution for every goal &mdash; let&apos;s build a game plan that&apos;s geared for your
        dealership
      </p>
      <h2 className="heading-4 text-center">Sign up to get started</h2>
      <form action={FORM_ACTION} method="POST">
        {Object.entries(HIDDEN_INPUTS).map(([name, value]) => (
          <input key={name} type="hidden" name={name} value={value} />
        ))}

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor="00NG000000Els9d">
            First Name
          </label>
          <input
            id="00NG000000Els9d"
            className="w-100"
            name="00NG000000Els9d"
            type="text"
            maxLength={60}
            placeholder="Enter your first name"
          />
        </div>

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor="00NG000000Els9e">
            Last Name
          </label>
          <input
            id="00NG000000Els9e"
            className="w-100"
            name="00NG000000Els9e"
            type="text"
            maxLength={60}
            placeholder="Enter your last name"
          />
        </div>

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor="00NG000000Els9c">
            Email Address
          </label>
          <input
            id="00NG000000Els9c"
            className="w-100"
            name="00NG000000Els9c"
            type="email"
            maxLength={80}
            placeholder="Enter your email address"
          />
        </div>

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor="00NG000000EiRcf">
            Dealership Name
          </label>
          <input
            id="00NG000000EiRcf"
            className="w-100"
            name="00NG000000EiRcf"
            type="text"
            maxLength={255}
            placeholder="Enter dealership name"
          />
        </div>

        <fieldset className="form-group mx-auto mb-1">
          <legend className="form-label text-cool-gray-30 size-12 fw-bold">Product(s)</legend>

          {productList.map(product => (
            <label key={product} className="product-item pe-2 d-block d-sm-inline-block">
              <input type="checkbox" name="00N0f00000G8qXR" value={product} /> {product}
            </label>
          ))}
        </fieldset>

        <div className="form-group mx-auto mb-1">
          <label className="form-label text-cool-gray-30 size-12 fw-bold" htmlFor="description">
            Tell us more about your goals (optional)
          </label>
          <textarea className="w-100" name="description" />
        </div>

        <div className="form-submit mx-auto mb-1">
          <input type="submit" className="w-100" value="Submit" />
        </div>

        <p className="form-disclaimer mx-auto mt-0_25 text-center text-cool-gray-30 size-12">
          Your information is safe with us. We&apos;ll only use it to explore your partnership with Edmunds
        </p>
      </form>
    </div>
  );
}
